<template>
  <v-app>
    <core-app-bar />

    <core-drawer />

    <core-snackbar />

    <core-view />
  </v-app>
</template>

<script>
  export default {
    name: 'Index',

    components: {
      CoreAppBar: () => import('@/components/admin/core/CoreAppBar'),
      CoreDrawer: () => import('@/components/admin/core/CoreDrawer'),
      CoreView: () => import('@/components/admin/core/CoreView'),
      CoreSnackbar: () => import('@/components/general/core/CoreSnackbar'),
    },

    data: () => ({

    }),
  }
</script>
